<template>
    <div>
        <!-- 主体内容	 -->
        <el-card>
          <el-breadcrumb>
            <el-breadcrumb-item :to="{ path: '/' }">
              <span class="nav_path"></span>首页
            </el-breadcrumb-item>
            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
            <el-breadcrumb-item>用户列表</el-breadcrumb-item>
          </el-breadcrumb>

          <div class="search_area" style="display: inline-block">
            <div style="display: inline;">
              <el-input placeholder="请输入用户名" v-model="queryInfo.query" class="input-with-select" clearable
                        @clear="getUserList" style="width: 320px">
                <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
              </el-input>
            </div>
            <div style="display:inline-block;">
              <el-button type="primary" icon="el-icon-plus" @click="addDialog" style="background-color: #42b7fb;border-color: #42b7fb;margin-left: 10px">添加用户</el-button>
              <el-button type="danger" icon="el-icon-delete" @click="batchDelete" :disabled="this.selection.length === 0" style="margin-left: 10px">批量删除</el-button>
            </div>
          </div>



            <!-- 列表用户内容区域	 -->
            <el-table :data="userList" :header-cell-style="{background: '#F6F6F6',fontWeight: '600',color: '#333',}"
                      style="width: 100%;border-top: 1px solid #f2f2f2" @selection-change="handleSelectionChange" ref="multipleTable">
                <el-table-column type="selection" width="55" :selectable="judgeSelect">
                </el-table-column>
                <el-table-column type="index" align="center" label="序号" min-width="60">
                </el-table-column>
                <el-table-column prop="username" align="center" label="用户名" min-width="120">
                </el-table-column>
                <el-table-column prop="role" align="center" label="角色" min-width="100" :formatter="RoleFormatter">
                </el-table-column>
                <el-table-column prop="password" show-overflow-tooltip align="center" label="密码" min-width="180" type="password">
                </el-table-column>
                <el-table-column prop="phone" align="center" label="电话" min-width="180">
                </el-table-column>
                <el-table-column prop="lastLogin" align="center" label="最近登录" min-width="150" :formatter="TimeFormatter">
                </el-table-column>
                <el-table-column prop="address" align="center" label="操作" min-width="200">
                  <template slot-scope="scope" v-if="scope.row.role !== 1">
                    <el-button class="primary" type="text" @click="editUserlist(scope.row)">编辑</el-button>
                    <span class="separator">|</span>
                    <el-button class="danger" type="text" @click="removeUser(scope.row.id)">删除</el-button>
<!--                    <span class="separator">|</span>-->
<!--                    <el-button class="primary" type="text" @click="setContact(scope.row.id, 1, scope.row.phone)" v-if="scope.row.contact==0">设为联系人</el-button>-->
<!--                    <el-button class="danger" type="text" @click="setContact(scope.row.id, 0, scope.row.phone)" v-else>取消联系人</el-button>-->
                  </template>
                </el-table-column>
            </el-table>
            <!-- 分页功能 -->
            <el-pagination align="left" @size-change="SizeChange" @current-change="CurrentChange"
                           :disabled="false"
                           :current-page="queryInfo.pagenum"
                           :page-sizes="[queryInfo.pagesize]" :page-size="queryInfo.pagesize"
                           layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>


        <!-- 添加用户dialog对话框 -->

        <el-dialog align="left" title="添加用户" :visible.sync="addUserVisible" width="550px" @close="closeAdduserDialog">
            <!--主体部分 -->
            <el-form :model="addUserForm" status-icon :rules="addUserFormRul" ref="addUserFormRel" label-width="100px"
                     class="demo-ruleForm">
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="addUserForm.username" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input type="password" v-model="addUserForm.password" autocomplete="off"></el-input>
                </el-form-item>
              <el-form-item label="角色" prop="role">
                <el-select v-model="addUserForm.role" filterable placeholder="请选择" :disabled="currentRol !== '1'">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
                <el-form-item label="手机" prop="phone">
                    <el-input v-model.number="addUserForm.phone"></el-input>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
              <el-button @click="addUserVisible = false">取 消</el-button>
              <el-button class="submit_btn" type="primary" @click="addUserList">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 修改用户diaolog对话框 -->

        <el-dialog align="left" title="修改用户" :visible.sync="editUserVisible" width="550px" @close="closeEdituserDialog">
            <!--主体部分 -->
            <el-form :model="editUserForm" status-icon :rules="addUserFormRul" ref="editUserFormRel" label-width="100px" class="demo-ruleForm">
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="editUserForm.username" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                  <el-input v-model="editUserForm.password" type="password"></el-input>
                </el-form-item>
                <el-form-item label="手机" prop="phone">
                    <el-input v-model.number="editUserForm.phone"></el-input>
                </el-form-item>
              <el-form-item label="角色">
                <el-select v-model="editUserForm.role" :disabled="currentRol !== '1'" filterable placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
      		<el-button @click="editUserVisible = false">取 消</el-button>
      		<el-button class="submit_btn" type="primary" @click="editUser">确 定</el-button>
      	</span>
        </el-dialog>

    </div>
</template>

<script>
    export default {
        data() {
            //验证手机号码的规则
            var checkMobile = (rule, value, cb) => {
                const regMobile = /^1[345789]\d{9}$/
                if (regMobile.test(value)) {
                    return cb()
                }
                //返回一个错误提示
                cb(new Error('请输入合法的手机号码'))
            }


            return {
                //搜索的neirong
                searchVal: '',
                //用户列表参数
                queryInfo: {
					      isSupplier: 0,
                    query: '',
                    pagenum: 1,
                    pagesize: 5
                },
                //用户列表
                userList: null,
                //总的数据条数
                total: 0,
                //添加用户dialog,控制显示隐藏
                addUserVisible: false,
                //添加用户参数
                addUserForm: {
                    username: '',
                    password: '',
                    role: '',
                    phone: ''
                },
                //添加用户规则
                addUserFormRul: {
                    username: [{
                        required: true,
                        message: '请输入用户名',
                        trigger: 'blur'
                    }],
                    password: [{
                        required: true,
                        message: '请输入密码',
                        trigger: 'blur'
                    },
                    {
                        min: 6,
                        message: '长度不能小于6个字符',
                        trigger: 'blur'
                    }
                    ],
                    phone: [
                        {
                          // validator: checkMobile,
                          pattern: /^1[345789]\d{9}$/,
                          message: '请输入合法的手机号',
                          trigger: 'change'
                        }
                    ],
                    role: [
                      {
                        required: true,
                        message: '请选择一个角色',
                        trigger: 'blur'
                      }
                    ]
                },

                //修改用户参数
                editUserForm: {
                    username: '',
                    role: '',
                    phone: '',
                    id: '',
                    password: ''
                },

                //修改用户dialog,控制显示隐藏
                editUserVisible: false,
                //角色对话框
                editRoleVisible: false,
                //分配角色
                selectVal: '',
                //当前用户
                currentname: '',
                //当前的角色
                currentRol: null,
                id: '',
                rid: '',
              options: [{
                value: 2,
                label: '管理员'
              },{
                value: 3,
                label: '库管员'
              }],
                selection: []
          }
        },
        mounted() {
            this.getUserList();
            this.currentRol = window.sessionStorage.getItem('role');
            if (this.currentRol !== null && this.currentRol !== '1') {
                this.addUserForm.role = this.options[1].value
            }
        },
        methods: {
            //请求用户列表
            getUserList() {
                this.$http.post('user_query', this.queryInfo).then(res => {
                    if (res.data.code !== 200) return this.$message.error("查询失败！" + res.data.msg);
                    this.userList = res.data.data.records;
                    this.total = res.data.data.total;
                })
            },
            //每页数据条数改变时触发
            SizeChange(newval) {
                this.queryInfo.pagesize = newval;
                this.getUserList();
            },
            //当前页面改变时触发
            CurrentChange(newval) {
                this.queryInfo.pagenum = newval;
                this.getUserList();
            },
            RoleFormatter(row, column){
                if(row.role === 1){
                    return "系统管理员"
                }else if(row.role === 2){
                    return "管理员"
                }else if(row.role === 3){
                    return "库管员"
                }else {
                    return "——"
                }
            },
            TimeFormatter(row, column){
                const moment = require('moment');
                const date = row[column.property];
                if(date == undefined){return ''}
                return moment(date).subtract(1,'months').format('yyyy-MM-DD HH:mm:ss')

        },

            //删除用户
            removeUser(id) {
              this.$confirm('是否继续删除该用户信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$http.post(`user_delete/${id}`)
                    .then(res => {
                      //console.log(res);
                      if (res.data.code == 400) return this.$message.warning(res.data.msg);
                      if (res.data.code !== 200) return this.$message.error("删除失败！" + res.data.msg);

                      this.$message.success('删除成功！');

                      //刷新列表
                      this.getUserList();
                    })
              });
            },
          setContact(id, flag, phone) {
            if (phone == null) {
              return this.$message.warning("联系人的电话不能为空");
            }
            let msg = "";
            if(flag == 1) {
              msg = "是否确认设置为联系人？";
            }else {
              msg = "是否取消设置为联系人？";
            }
            this.$confirm(msg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$http.post(`contact/${id}/${flag}`)
                  .then(res => {
                    //console.log(res);
                    if (res.data.code == 400) return this.$message.warning(res.data.msg);
                    if (res.data.code !== 200) return this.$message.error("设置失败！" + res.data.msg);

                    this.$message.success('设置成功！');

                    //刷新列表
                    this.getUserList();
                  })
            });
          },
            //点击添加新用户
            addDialog() {
                this.addUserVisible = !this.addUserVisible;
            },
            //点击确定发送添加新用户接口
            addUserList() {
                this.$refs.addUserFormRel.validate(valid => {
                    //校验规则全部正确向后台发送请求
                    if (!valid) return;
                    this.$http.post('register', this.addUserForm).then(res => {
                        //console.log(res);
                        if (res.data.code !== 200) return this.$message.error("添加失败！" + res.data.msg);
                        this.$message.success('添加成功！');
                        //刷新列表
                        this.getUserList();
                        this.addUserVisible = !this.addUserVisible;
                    })
                })
            },
            //关闭添加用户对话框，重置表单
            closeAdduserDialog() {
                this.$refs.addUserFormRel.resetFields();
            },
            closeEdituserDialog() {
              this.$refs.editUserFormRel.resetFields();
            },
            //修改用户信息事件
            editUserlist(item) {
                this.editUserVisible = !this.editUserVisible;
                this.editUserForm = {
                    username: item.username,
                    role: item.role,
                    phone: item.phone,
                    id: item.id,
                    password: item.password
                }
            },
            //点击确定发送请求修改用户信息
            editUser() {
              this.$refs.editUserFormRel.validate(valid => {
                //校验规则全部正确向后台发送请求
                if (!valid) return;
                this.$http.post('user_update', this.editUserForm).then(res => {
                  if (res.data.code !== 200) return this.$message.error("修改失败！" + res.data.msg);
                  this.$message.success("修改成功！");
                  this.getUserList();
                  this.editUserVisible = !this.editUserVisible;
                })
              })

            },
            judgeSelect(row, index) {
                return row.id !== 1
            },
            handleSelectionChange(val) {
                this.selection = val;
            },
            batchDelete() {
                this.$confirm('删除后无法恢复，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let ids = this.selection.map((item) => item.id);
                    this.$http.post('user_batch_delete', ids)
                        .then(res => {
                            if (res.data.code === 400) return this.$message.warning(res.data.msg);
                            if (res.data.code !== 200) return this.$message.error("删除失败！" + res.data.msg);

                            this.$message.success('删除成功！');

                            //刷新列表
                            this.getUserList();
                        })
                }).catch(action => {
                    if (action === "cancel") {
                        this.$refs.multipleTable.clearSelection();
                        this.selection = [];
                    }
                });
            }
        }
    }
</script>

<style scoped>
.demo-ruleForm .el-input {
  width: 70%;
}
.search_area {
  float: right;
  margin-bottom: 20px;
}
.el-breadcrumb {
  font-size: 14px;
  display: inline;
  float: left;
  margin: 15px 0 0 5px
}

.primary {
  font-size: 16px;

  font-weight: 500;
  color: #42B7FB;
}
.separator {
  color: #ccc;
  padding: 0 5px;
}
.danger {
  font-size: 16px;

  font-weight: 500;
  color: #ff4d51;
}
.danger:hover {
  font-size: 16px;

  font-weight: 500;
  color: #ff4d51;
}
.submit_btn {
  background: #42b7fb !important;
  color: #fff !important;
  border: none !important;
}
/deep/.el-table .cell:empty::before {
  content: "—";
  color: #999;
}
</style>
